import geral from './geral.config';

const configs = {
    geral: {
        ...geral
    },
    titles: {
        SECTOR: 'Perfil'
    },
    icons: {
        role: {
            USER: 'face',
            MANAGER: 'pregnant_woman',
            COMPANY: 'school',
            get(role) {
                return this[role];
            }
        }
    },
    fields: {
        age: {
            LABEL: 'Idade'
        },
        gender: {
            LABEL: 'Qual seu gênero?',
            answers: {
                LABEL_MALE: 'Masculino',
                LABEL_FERMALE: 'Feminino',
                LABEL_BINARY: 'Não binário'
            }
        }
    },
    buttons: {
        SUBMIT: 'Efetivar perfil'
    },
    messages: {
        age: {
            errors: {
                CHECK_AGE: 'Informe sua idade!'
            }
        },
        info: {
            title: {
                get(userName) {
                    return `Ola ${userName}!`;
                }
            },
            SUB_TITLE: 'Para utilizar o sistema, preencha os campos idade e sexo!'
        }
    }
};

export default configs;
