<template>
    <v-container fill-height>
        <v-layout justify-center align-center>
            <v-flex xs12 sm6 md4 lg3 xl3>
                <SanackBar
                    :snackActivated="snackActivated"
                    :success="snackSuccess"
                    :snackMessage="snackMessage"
                    @closeSnackBar="closeSnack()" />

                <v-card class="elevation-12">
                    <v-toolbar color="primary" dark>
                        <v-toolbar-title>{{ titles.toolbar }}</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-progress-circular
                            indeterminate
                            color="white"
                            width="2"
                            v-show="loadingActivated">
                        </v-progress-circular>
                    </v-toolbar>

                    <v-card-text v-if="submitedForm">
                        <v-container class="box-submited">
                            <h1 class="info-title">Conta ativada!</h1>
                            <p class="info-msg">Sua conta foi ativada corretamente!</p>
                            <div class="redirect">
                                Redirecionando em {{ timeToRedirect }} segundos...
                            </div>
                        </v-container>
                    </v-card-text>

                    <v-card-text v-else>
                        <v-container>
                            <v-row>
                                <v-col align="center">
                                    <v-icon x-large>
                                        {{ getIconUserTypeComputed(user.role) }}
                                    </v-icon>

                                    <h1 class="user-name">
                                        {{ getMessageHelloWordComputed('title') }}
                                    </h1>

                                    <p class="msg">
                                        {{ getMessageHelloWordComputed('subtitle') }}
                                    </p>
                                </v-col>
                            </v-row>
                        </v-container>

                        <v-form>
                            <v-container>
                                <v-row>
                                    <v-col>
                                        <v-container class="c-age">
                                            <v-slider
                                                v-model="user.age"
                                                :label="getLabelFieldComputed('age')"
                                                :min="ageIntervals.init"
                                                :max="ageIntervals.end"
                                                thumb-label="always"></v-slider>
                                        </v-container>

                                        <v-container class="c-gender">
                                            <p class="gender">
                                                {{ getLabelGenderFieldComputed('label') }}
                                            </p>
                                            <v-radio-group
                                                v-model="user.gender"
                                                mandatory>
                                                <v-radio
                                                    :label="
                                                        getLabelGenderFieldComputed(
                                                            'answers',
                                                            'LABEL_FERMALE'
                                                        )
                                                    "
                                                    :disabled="loadingActivated"
                                                    value="f">
                                                </v-radio>

                                                <v-radio
                                                    :label="
                                                        getLabelGenderFieldComputed(
                                                            'answers',
                                                            'LABEL_MALE'
                                                        )
                                                    "
                                                    :disabled="loadingActivated"
                                                    value="m">
                                                </v-radio>

                                                <v-radio
                                                    :label="
                                                        getLabelGenderFieldComputed(
                                                            'answers',
                                                            'LABEL_BINARY'
                                                        )
                                                    "
                                                    :disabled="loadingActivated"
                                                    value="n">
                                                </v-radio>
                                            </v-radio-group>
                                        </v-container>

                                        <submit-form-button
                                            :title="titles.submitButton"
                                            :disabled="loadingActivated"
                                            @submitForm="submitForm()" />
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-form>
                    </v-card-text>
                </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
    import { mapGetters } from 'vuex';
    import SanackBar from '@/components/snack/SanackBar.vue';
    import { isAmbientGeralTests } from '@/configs/global.config';
    import geralConfig from '../configs/geral.config';
    import activeProfileConfig from '../configs/active.profile.config';
    import userMock from '../mocks/active.profile.mock';
    import SubmitFormButton from '../components/geral/SubmitFormButton.vue';

    export default {
        components: { SanackBar, SubmitFormButton },
        name: 'ActiveProfile',
        data() {
            return {
                isAmbientLocalTests: false,
                submitedForm: false,
                loadingActivated: false,
                snackMessage: null,
                snackSuccess: false,
                snackActivated: false,
                timeToRedirect: 5,
                user: {
                    id: null,
                    name: null,
                    role: null,
                    age: null,
                    gender: null
                },
                ageIntervals: {
                    init: 0,
                    end: 105
                },
                titles: {
                    toolbar: activeProfileConfig.titles.SECTOR,
                    submitButton: activeProfileConfig.buttons.SUBMIT
                }
            };
        },
        mounted() {
            if (isAmbientGeralTests && this.isAmbientLocalTests) {
                this.populateMock();
            } else {
                this.populateUser();
            }
        },
        computed: {
            ...mapGetters({
                userData: 'getDataUser'
            }),
            getFirstNameComputed() {
                return this.user.name ? this.user.name.split(' ')[0] : 'Flin';
            },
            getIconUserTypeComputed() {
                return (role) => {
                    return activeProfileConfig.icons.role.get(role);
                };
            },
            getLabelFieldComputed() {
                return (nameField) => {
                    return activeProfileConfig.fields[nameField].LABEL;
                };
            },
            getLabelGenderFieldComputed() {
                return (type, answer = null) => {
                    if (type === 'label') {
                        return activeProfileConfig.fields.gender.LABEL;
                    }

                    if (type === 'answers' && answer) {
                        return activeProfileConfig.fields.gender.answers[answer];
                    }

                    return null;
                };
            },
            getMessageHelloWordComputed() {
                return (option) => {
                    if (option === 'title') {
                        return activeProfileConfig.messages.info.title.get(
                            this.getFirstNameComputed
                        );
                    }

                    if (option === 'subtitle') {
                        return activeProfileConfig.messages.info.SUB_TITLE;
                    }

                    return null;
                };
            }
        },
        methods: {
            populateMock() {
                this.users = Object.assign(this.user, userMock);
            },
            populateUser() {
                this.user = Object.assign(this.user, this.userData);
            },
            closeSnack() {
                this.snackActivated = false;
            },
            activeSnack(message, success) {
                this.snackActivated = true;
                this.snackSuccess = success;
                this.snackMessage = message;
            },
            goTo() {
                return this.$router.push({ name: 'queue' });
            },
            redirect() {
                setInterval(() => this.timeToRedirect--, 1000);

                setTimeout(() => this.goTo(), this.timeToRedirect * 1000);
            },
            filterMessageError(e) {
                const { message } = JSON.parse(JSON.stringify(e));

                if (message === 'Network Error') {
                    return {
                        field: null,
                        message: 'Erro de conexão com a API!'
                    };
                } else {
                    const { error } = e.response.data.status.payload;

                    const map = {
                        default: {
                            message: 'Erro genérico!'
                        },
                        ageDataInvalid: {
                            message: 'O valor da idade é inválido!'
                        },
                        genderDataInvalid: {
                            message: 'O valor do gênero sexual é inválido!'
                        }
                    };

                    const exists = map.hasOwnProperty(error);

                    return exists ? map[error] : map['default'];
                }
            },
            ageValidate() {
                let valid = true,
                    message = null;

                if (!this.user.age) {
                    valid = false;
                    message = activeProfileConfig.messages.age.errors.CHECK_AGE;
                }
                return {
                    valid,
                    message
                };
            },
            validForm() {
                const ageField = this.ageValidate();

                if (!ageField.valid) {
                    this.activeSnack(ageField.message, false);

                    return false;
                }

                return true;
            },
            submitForm() {
                if (!this.validForm()) return;

                this.loadingActivated = true;

                this.$service.http[geralConfig.service.http]
                    .activeProfile(this.user)
                    .then((_) => {
                        this.submitedForm = true;

                        this.redirect();
                    })
                    .catch((e) => {
                        const { message } = this.filterMessageError(e);

                        this.activeSnack(message, false);
                    })
                    .finally((_) => {
                        this.loadingActivated = false;
                    });
            }
        }
    };
</script>

<style scoped>
    .box-submited {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        text-align: center;
    }

    .box-submited .info-title {
        margin-top: 10px;
    }

    .box-submited .info-msg {
        margin-top: 10px;
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover,
    textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
        position: relative !important;
        /* -webkit-box-shadow: 0 0 0px 0px transparent outset; */
        transition: background-color 5000s ease-in-out 0s;
    }

    .user-name {
        margin-top: 10px;
        font-size: 28px;
        font-weight: 700;
    }

    .msg {
        margin-top: 10px;
    }

    .container .c-age {
        margin-top: 20px;
        padding: 0px !important;
        padding-left: 5px;
        padding-right: 5px;
    }

    .container .c-gender {
        padding: 0px !important;
        padding-left: 5px;
        padding-right: 5px;
    }

    .container .c-gender .gender {
        font-size: 16px;
        font-weight: bold;
    }

    .redirect {
        margin-top: 20px;
        margin-bottom: 20px;
    }
</style>
